<template>
  <form id="product-form" :disabled="loading" @submit.prevent="submitForm">
    <loader v-if="loading" :loading-message="loadingMessage" />

    <hr class="divider divider--tiny grey-light" />

    <!-- Step Menu button -->
    <product-form-menu
      :views="views"
      :selectedIndex="viewIndex"
      @select-index="i => (viewIndex = i)"
    />

    <div class="form-row">
      <span
        role="button"
        :class="`h6 ${viewIndex === 0 ? 'grey-white' : 'grey'}--text`"
        @click.stop="viewIndex = Math.max(viewIndex - 1, 0)"
        ><i v-if="viewIndex > 0" class="fas fa-arrow-left"></i>
        <hr class="divider divider--vertical" />
        {{ viewIndex === 0 ? "Start" : "Back" }}
      </span>
      <!-- Products -->
      <product-form-products
        class="grow"
        :type="type"
        :form-data="form"
        @change="appendAndEmit"
        v-show="viewIndex === 0"
      />

      <!-- Card Fields -->
      <fieldset v-show="viewIndex === 1" class="fields slide-in-right">
        <legend>
          <i class="fas fa-credit-card accent--text"></i>
          Payment Details
        </legend>
        <!-- Name, Email -->
        <label
          class="column wide"
          v-for="field in prefilledUser"
          :key="field.key"
        >
          <span class="label">{{ field.text }}</span>
          <input disabled v-model="form[field.key]" class="wide" type="text" />
        </label>

        <!-- Card inputs  -->
        <label class="column wide"><span class="label">Card</span></label>
        <div id="card-inputs"></div>

        <hr class="divider divider--tiny" />
      </fieldset>

      <!-- Legal consent and Submit -->
      <product-form-consent-submit
        v-if="viewIndex === 2"
        :disable-submit="loading"
        :error-message="error"
        :form-data="form"
        :selected-product="form.selectedProduct"
        :price="form.price"
        @change="appendAndEmit"
        @submit="submitForm"
      />

      <span
        role="button"
        :class="`h6 ${lastViewActive ? 'grey-white' : 'grey'}--text`"
        @click.stop="viewIndex = Math.min(viewIndex + 1, views.length - 1)"
        >{{ lastViewActive ? "Finish!" : "Next" }}
        <hr class="divider divider--vertical"/>
        <i v-if="!lastViewActive" class="fas fa-arrow-right"></i
      ></span>
    </div>

    <!-- Step Menu button -->
    <product-form-menu
      :views="views"
      :selectedIndex="viewIndex"
      @select-index="i => (viewIndex = i)"
    />
  </form>
</template>

<script>
/* eslint-disable no-undef */
import * as MVPStripe from "../models/stripe";
import state from "../state";
import Loader from "./Loader.vue";
import User from "../models/user.model";
import { startLimitedMembership, updateUser } from "../models/user";
import PermissionsMixin from "./mixins/permissions.mixin";
import FormsMixin from "./mixins/forms.mixin";
import ProductFormMenu from "./ProductForm.Menu.vue";
import ProductFormProducts from "./ProductForm.Products.vue";
import ProductFormConsentSubmit from "./ProductForm.ConsentSubmit.vue";

export default {
  name: "ProductForm",

  components: {
    Loader,
    ProductFormMenu,
    ProductFormProducts,
    ProductFormConsentSubmit
  },

  mixins: [PermissionsMixin, FormsMixin],

  props: { productId: String, type: String },

  data: () => ({
    name: null,
    email: null,
    card: null,
    prefilledUser: [
      { text: "Billing Name", key: "name" },
      { text: "Email", key: "email" }
    ],
    viewIndex: 0,
    views: [
      { icon: "shopping-cart", text: "Checkout" },
      { icon: "credit-card", text: "Payment" },
      { icon: "file-contract", text: "Consent" }
    ]
  }),

  computed: {
    lastViewActive() {
      return this.viewIndex === this.views.length - 1;
    },
    requiredFields() {
      const fields = ["name", "email", "cardConsent"];
      if (!this.form.donationAmount) fields.push("selectedProduct");
      if (this.subscriptionProduct) fields.push("membershipConsent");
      return fields;
    },
    selectedProductDescription() {
      const { selectedProduct } = this.form;
      return selectedProduct
        ? selectedProduct.description
        : "Select a product to continue";
    },
    subscriptionProduct() {
      const { price } = this.form;
      return (price || {}).type === "recurring";
    }
  },

  async mounted() {
    this.startLoading("Initializing form...");
    // Setup initial data
    if (this.activeUser) this.initStripeForm();
    // Finish loading
    this.stopLoading();
  },

  beforeDestroy() {
    this.card.unmount("#card-inputs");
    this.card = null;
  },

  methods: {
    async checkoutDonation(amount) {
      try {
        // Create payment intent (so user gets charged once) and process payment
        const { stripe } = state.getState();
        const { email, stripeAcctId, fullName: name } = this.activeUser;
        const [paymentMethod, intentId] = await Promise.all([
          this.genPaymentMethod(),
          MVPStripe.createDonationIntent({
            amount,
            customerId: stripeAcctId,
            email,
            name
          })
        ]);
        const res = await stripe.confirmCardPayment(intentId, {
          receipt_email: email,
          payment_method: paymentMethod.id
        });
        // Handle successful payment
        return res.error
          ? this.onViewError(res.error.message)
          : this.onPaymentConfirmation(res.paymentIntent);
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    async checkoutSinglePurchase(price) {
      try {
        // Create payment intent (so user gets charged once) and process payment
        const { stripe } = state.getState();
        const { email, stripeAcctId } = this.activeUser;
        const [paymentMethod, intentId] = await Promise.all([
          this.genPaymentMethod(),
          MVPStripe.createPaymentIntent({
            customerId: stripeAcctId,
            productId: price.product,
            userEmail: email,
            price
          })
        ]);
        const res = await stripe.confirmCardPayment(intentId, {
          receipt_email: email,
          payment_method: paymentMethod.id
        });
        // Handle successful payment
        if (res.error) return this.onViewError(res.error.message);

        // Update non-recurring membership if present
        const { selectedProduct } = this.form;
        const { metadata = {} } = selectedProduct;
        if ((metadata || {}).membership) {
          return await this.updateUserMembership(res, price);
        }

        this.onPaymentConfirmation(res.paymentIntent);
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    async checkoutSubscription(price) {
      try {
        const paymentMethod = await this.genPaymentMethod();
        const subscription = await MVPStripe.createSubscription({
          customerId: this.activeUser.stripeAcctId,
          paymentMethodId: paymentMethod.id,
          priceId: price.id
        });

        // Update user (server will set 'stripeSubActive'='true'). Updates
        // the this.activeUser prop
        await updateStateUser(this.activeUser);
        return this.onPaymentConfirmation(subscription);
      } catch (err) {
        this.onViewError(err.message || err);
      }
    },

    async genPaymentMethod() {
      const { stripe } = state.getState();
      const { card, activeUser } = this;
      const { email, fullName: name } = activeUser;
      const opts = { type: "card", card, billing_details: { name, email } };
      // create payment method
      const { paymentMethod, error } = await stripe.createPaymentMethod(opts);
      const e = error || paymentMethod.error;
      // Handle errors
      if (e) throw e;

      return paymentMethod;
    },

    initStripeForm() {
      // Create and mount Form input elements
      const { stripe } = state.getState();
      const elements = stripe.elements(MVPStripe.stripeElementOpts);
      this.card = elements.create("card");
      this.card.mount("#card-inputs");
      this.card.on("change", this.validateInputs);

      // Pre-fill form data with active (logged-in) user
      const { fullName: name, email } = this.activeUser;
      this.appendAndEmit({ name, email });
    },

    async onPaymentConfirmation(response) {
      return this.$emit("payment-complete", response);
    },

    async submitForm() {
      if (this.loading) return;
      this.startLoading("Beginning transaction...");

      const { activeUser, form } = this;
      const price = form.price || form.donationAmount;
      const hasAgreements =
        !activeUser.cardAgreement ||
        (!activeUser.membershipAgreement && price.type === "reurring");

      // Update user card/membership agreements if either changed
      if (hasAgreements) await this.updateUserAgreements();
      // Create Stripe customer (optional)
      if (!activeUser.stripeAcctId) await createStripeCustomer(activeUser);

      this.startLoading("Completing transaction...");

      try {
        if (!isNaN(price)) {
          // donations
          await this.checkoutDonation(price);
        } else if (price.type === "one_time") {
          // drop-in, one-time event
          await this.checkoutSinglePurchase(price);
        } else if (price.type === "recurring") {
          // membership checkout
          await this.checkoutSubscription(price);
        }
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    // Update user card/membership consent if either changed to true
    async updateUserAgreements() {
      await updateStateUser({
        ...this.activeUser,
        cardAgreement: this.form.cardConsent,
        membershipAgreement: this.form.membershipConsent
      });
    },

    async updateUserMembership(paymentRes, price) {
      const nickname = (price.nickname || "").toLowerCase();
      const membership = o => startLimitedMembership(this.activeUser, o);
      this.startLoading("Completing transaction ... ");

      switch (true) {
        // 1 month membership
        case new RegExp("1 month").test(nickname):
          state.user(await membership({ months: 1 }));
          break;

        // 1 week (paid) membership
        case new RegExp("1 week").test(nickname):
          state.user(await membership({ weeks: 1 }));
          break;

        default:
          break;
      }

      this.stopLoading();
      return this.onPaymentConfirmation(paymentRes);
    },

    validateInputs({ error }) {
      this.onViewError(error ? error.message : null);
    }
  }
};

async function createStripeCustomer(user) {
  const { firstName, lastName, email } = user;
  const params = { firstName, lastName, email };
  const customer = await MVPStripe.createCustomer(params);
  // update state user
  const { id: stripeAcctId } = customer;
  return updateStateUser({ ...user, stripeAcctId });
}

async function updateStateUser(params) {
  const user = await updateUser(User(params));
  state.user(user);
  return true;
}
</script>

<style lang="scss" src="./ProductForm.scss"></style>

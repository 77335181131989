<template>
  <!-- Products -->
  <fieldset class="product-form--products">
    <legend>
      <i class="fas fa-shopping-cart accent--text"></i>
      {{ showDonationForm ? `Choose Donation` : "Select an Item" }}
    </legend>

    <!-- Donations -->
    <product-form-donations v-if="showDonationForm" @change="appendAndEmit" />

    <!-- Products list (Class: [Drop-in, Membership] | Event: has productId) -->
    <template v-if="products.length">
      <div
        class="product column"
        v-for="(product, i) in products"
        :key="product.id"
      >
        <!-- Name + Desc -->
        <h3 class="name h5">{{ product.name }}</h3>
        <p class="description">{{ product.description }}</p>

        <!-- Prices -->
        <div class="prices form--row">
          <div
            class="price"
            :class="{ active: form.price === price }"
            role="button"
            v-for="price in product.price"
            :key="price.id"
            @click.prevent="appendAndEmit({ selectedProduct: product, price })"
          >
            <!-- Icon -->
            <i
              class="fas"
              :class="{
                'fa-usd-circle success--text': form.price === price,
                'fa-dollar-sign': form.price !== price
              }"
              style="font-size: 1rem"
            />
            <!-- Price number -->
            <span class="price__value">{{ price.displayPrice }}</span>
            <!-- Price description -->
            <span class="price__desc">
              {{ price.type === "recurring" ? "Subscription" : `One-time` }}
            </span>
          </div>
        </div>

        <!-- Selected item description -->
        <p class="description success" v-if="form.selectedProduct === product">
          {{ form.price.nickname || `$${form.price.displayPrice}` }}
        </p>

        <branded-divider class="low-margin" v-if="i < products.length - 1" />
      </div>
    </template>
  </fieldset>
</template>

<script>
import { getProductById, listMembershipProducts } from "../models/stripe";
import state from "../state";
import BrandedDivider from "./BrandedDivider.vue";
import FormsMixin from "./mixins/forms.mixin";
import ProductFormDonations from "./ProductForm.Donations.vue";

export default {
  name: "ProductFormProducts",

  components: { BrandedDivider, ProductFormDonations },

  mixins: [FormsMixin],

  props: { type: String },

  data: () => ({
    donationAmounts: [50, 75, 100],
    loading: true,
    products: [],
    showDonationForm: false
  }),

  computed: {
    requiredFields() {
      return this.showDonationForm
        ? ["donationAmount"]
        : ["selectedProduct", "price"];
    },

    formIsValid() {
      return this.showDonationForm
        ? this.form.donationAmount >= 50
        : Boolean(this.form.selectedProduct);
    }
  },

  async mounted() {
    const { activeProductId } = state.getState();

    switch (true) {
      // Fetch Stripe product if there is a product ID, or list all Membership products
      case Boolean(activeProductId):
        if (activeProductId === "DONATION") this.activateDonation(0);
        else {
          this.products = [await getProductById(activeProductId)];
          console.log(
            "* mounted | fetched product:id",
            activeProductId,
            this.products
          );
        }
        break;

      case this.type === "Class":
        this.products = await listMembershipProducts();
        console.log("* mounted fetched all products", this.products);
        break;

      default:
        this.activateDonation(50);
        break;
    }

    this.loading = false;
  },

  methods: {
    activateDonation(donationAmount = 0) {
      this.showDonationForm = true;
      this.appendAndEmit({ donationAmount });
    }
  }
};
</script>

<style lang="scss">
.product-form--products {
  flex-grow: 1;
  flex-wrap: wrap;
  padding-bottom: $md;

  &,
  .price,
  .prices {
    @include flex-center;
  }

  .description {
    padding: $sm $xs;
    text-align: center;

    &.success {
      @include heading-font;
      @include rounded;
      color: $success-dark;
      background: $success-light;
      font-weight: bold;
      margin-top: $xs;
      padding: $xxs;
    }
  }

  .description,
  .price__desc,
  .product {
    width: $parent;
  }

  .price {
    @include slide-in-left;
    background-color: lighten($color: $grey-white, $amount: 3);
    border: $xxxs solid $grey-light;
    cursor: pointer;
    flex-grow: 0;
    flex-wrap: wrap;
    height: 8rem;
    transition: flex-grow 125ms ease-in;
    width: 8rem;
    will-change: flex-grow;

    &:hover {
      border-color: $grey;
    }

    &.active {
      background-color: white;
      border-color: $success;
      flex-grow: 1;

      &,
      .price__value {
        color: $success;
      }
    }
  }

  .price__desc {
    font-size: $xs;
    text-align: center;
  }

  .price__value {
    @include h3;
    letter-spacing: 0.01rem;
    margin-left: $xxs;
  }

  sup {
    font-size: initial;
  }

  .name {
    @include center-text;
    text-transform: initial;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 768px) {
  .product-form--products {
    &,
    > * {
      width: $parent;
    }

    .button {
      box-shadow: none;
    }

    .price {
      margin-bottom: $tiny;
      margin-left: 0;
      margin-right: 0;
      margin-top: $tiny;
      width: $parent;
    }
  }
}

@media screen and (max-width: 500px) {
  .product-form--products {
    flex-direction: column;
  }
}
</style>

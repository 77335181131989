<template>
  <ul class="product-form--menu" role="menu">
    <li
      :key="view.text"
      @click.stop="$emit('select-index', i)"
      class="inline"
      role="button"
      v-for="(view, i) in views"
    >
      <span v-if="selectedIndex !== i" class="icon h6">{{ i + 1 }}</span>
      <i v-else :class="`icon fas fa-${view.icon} ${buttonColor(i)}--text`" />
      <span :class="`h5 ${buttonColor(i)}--text step-label`">{{
        view.text
      }}</span>
    </li>
  </ul>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "ProductFormMenu",

  props: { views: Array, selectedIndex: Number },

  methods: {
    buttonColor(i) {
      return i === this.selectedIndex ? "success" : "accent";
    }
  }
};
</script>

<style lang="scss">
.product-form--menu {
  display: flex;
  place-content: center;
  padding: $sm 0;

  [role="button"] {
    cursor: pointer;
    height: $lg;
    padding-left: $xxs;
    padding-right: $xxs;
    place-content: center;
    width: $third;

    .icon {
      align-items: center;
      background-color: white;
      border-radius: $sm;
      display: inline-flex;
      font-size: $sm;
      height: $md;
      line-height: $sm;
      place-content: center;
      width: $md;
    }

    .step-label {
      font-size: $sm;
      padding-left: $xxxs;
      letter-spacing: $xtiny;
    }
  }
}
</style>

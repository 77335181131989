<template>
  <fieldset class="product-form--consent-submit">
    <legend>
      <i class="fas fa-file-contract accent--text"></i>
      Payment Consent
    </legend>

    <div class="refund-notice">
      <em>
        <b>
          <span class="fas fa-exclamation-triangle accent--text"></span> Please
          note:
        </b>
        No refunds or returns will be issued for online streaming content. This
        includes, but is not limited to, all live stream classes, workshops,
        online memberships, and class packs. This is exclusive of those events
        canceled or rescheduled specifically by My Vinyasa Practice.
      </em>
    </div>

    <!-- Card Consent -->
    <label
      for="cardConsent"
      v-if="requiresCardConsent"
      @click.stop="appendFormData({ cardConsent: !form.cardConsent })"
      class="flex form--row"
    >
      <input
        type="checkbox"
        name="cardConsent"
        @change="emitFormData"
        v-model="form.cardConsent"
      />

      <span class="checkbox-label">
        By checking this box, I authorize
        <strong>My Vinyasa Practice</strong> to charge my card.
      </span>
    </label>

    <!-- Membership consent -->
    <label
      for="membershipConsent"
      v-if="requiresMembershipConsent"
      @click.stop="
        appendFormData({ membershipConsent: !form.membershipConsent })
      "
      class="flex form--row"
    >
      <input
        type="checkbox"
        name="membershipConsent"
        @change="emitFormData"
        v-model="form.membershipConsent"
      />

      <span class="checkbox-label">
        By checking this box, I consent to
        <strong>My Vinyasa Practice's</strong>&nbsp;
        <a
          :href="`${appUrl}/vendor/legal/mvp-member_agreement.pdf`"
          target="_blank"
          rel="noopener noreferrer"
          >membership agreement</a
        >.
      </span>
    </label>

    <!-- Selected Product -->
    <div class="line-items" v-if="selectedProduct">
      <div class="list-item--line-item">
        <b class="h6 wide">{{ selectedProduct.name }},</b>
        <span class="col-10 no-shrink">
          {{ `$${price.unit_amount / 100}` }}
        </span>
        <hr class="divider divider--vertical" />
      </div>

      <p class="list-item--line-item" style="font-size: smaller">
        {{ price.nickname || selectedProduct.description }}
      </p>
    </div>

    <!-- Donation Amount -->
    <div class="line-items" v-else-if="donationAmount">
      <div class="list-item--line-item">
        <b class="h6 wide">One-time Donation,</b>
        <b class="success--text no-shrink">${{ donationAmount }} </b>
        <hr class="divider divider--vertical" />
      </div>

      <div class="list-item--line-item" style="font-size: smaller">
        A one-time donation to help support {{ appOrgName }}
      </div>
    </div>

    <!-- Select Product notification -->
    <p class="line-items list-item--label" v-else>
      <em class="error--text"> Please select a product! </em>
    </p>

    <!-- Error notification -->
    <p class="list-item--notification error--text">
      <span>{{ errorMessage || error || "" }}</span>
    </p>

    <!-- Submission button -->
    <button
      :disabled="preventSubmit"
      class="success slide-down-fade-in wide"
      @click.prevent="$emit('submit')"
    >
      {{ submitButtonText }}
    </button>
  </fieldset>
</template>

<script>
/* eslint-disable no-undef */
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "ProductFormConsentSubmit",

  props: {
    disableSubmit: Boolean,
    errorMessage: String,
    price: Object,
    selectedProduct: Object
  },

  mixins: [PermissionsMixin, FormsMixin],

  data: () => ({ loading: false }),

  computed: {
    donationAmount() {
      const src = this.formData || { donationAmount: null };
      return src.donationAmount;
    },

    preventSubmit() {
      if (this.disableSubmit) return this.disableSubmit;
      return this.donationAmount ? this.donationAmount < 50 : !this.formIsValid;
    },

    requiredFields() {
      const fields = [];
      if (this.requiresCardConsent) fields.push("cardConsent");
      if (this.requiresMembershipConsent) fields.push("membershipConsent");
      return fields;
    },

    requiresCardConsent() {
      const { cardAgreement } = this.activeUser || {};
      return !cardAgreement;
    },

    requiresMembershipConsent() {
      const { membershipAgreement } = this.activeUser || {};
      return this.subscriptionSelected && !membershipAgreement;
    },

    submitButtonText() {
      return this.subscriptionSelected
        ? "Begin Subscription"
        : "Complete transaction";
    },

    subscriptionSelected() {
      const { price } = this.form;
      return (price || {}).type === "recurring";
    }
  },

  mounted() {
    this.initializeForm();
  },

  methods: {
    initializeForm() {
      // set initial form state
      this.appendFormData({
        cardConsent: !this.requiresCardConsent,
        membershipConsent: !this.requiresMembershipConsent
      });
    }
  }
};
</script>

<style lang="scss">
.product-form--consent-submit {
  @include slide-in-right;
  width: $parent;

  > label {
    display: flex;
    align-items: center;
  }

  .line-items {
    @include flex-column;
    @include rounded;
    background-color: $grey-white;
    border-color: $grey-light;
    padding: $sm $xxs;
    margin: $sm 0;
  }

  .list-item--line-item {
    border-color: $link;
    justify-content: space-between;
    width: $parent;
  }

  .list-item--notification {
    height: $xlg;
  }

  .refund-notice {
    border: 1px solid $grey-white;
    color: black;
    font-size: $xs;
    padding: $sm;
  }
}
</style>

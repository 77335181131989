<template>
  <section class="product-form--donations">
    <div class="donation-amounts form--row">
      <button
        v-for="amt in donationPresets"
        :class="`button grow${amt === form.donationAmount ? ' outline' : ''}`"
        :key="amt"
        @click.prevent="appendAndEmit({ donationAmount: amt })"
      >
        ${{ amt }}
      </button>
    </div>

    <label class="donation-amount" for="donationAmount">
      <b class="checkbox-label h6 wide">Or enter amount ($50 minimum):</b>
      <span class="form--row wide">
        <span class="h4 success--text">$</span>
        <input
          class="grow"
          type="number"
          name="donationAmount"
          v-model="form.donationAmount"
          @change="setDonation($event.target.value)"
          :aria-invalid="!formIsValid"
          :min="50"
        />
      </span>
    </label>

    <div class="grey--text" v-if="form.donationAmount && !formIsValid">
      <span class="checkbox-label">
        <i class="fas fa-heart error--text"></i>
        Won't you open your heart (and purse) to the <b>$50</b> minimum?
      </span>
    </div>

    <hr class="divider divider--tiny" v-else />
  </section>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "ProductFormProducts",

  mixins: [FormsMixin],

  data: () => ({ donationPresets: [50, 75, 100] }),

  computed: {
    requiredFields() {
      return ["donationAmount"];
    },

    formIsValid() {
      return this.form.donationAmount >= 50;
    }
  },

  async mounted() {
    this.appendAndEmit({ donationAmount: 50 });
  },

  methods: {
    setDonation(value) {
      return this.appendAndEmit({ donationAmount: Number(value) });
    }
  }
};
</script>

<style lang="scss">
.donation-amount {
  @include flex-column;
}

.donation-amounts {
  .button {
    margin-right: $sm;
  }
}

.product-form--donations {
  @include slide-in-left;
  @include flex-center;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .product-form--donations {
    width: $parent;

    > * {
      width: $parent;
    }

    .button {
      box-shadow: none;
    }

    .product,
    .product:first-of-type {
      margin-bottom: $tiny;
      margin-left: 0;
      margin-right: 0;
      margin-top: $tiny;
    }
  }
}
</style>
